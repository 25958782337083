import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyAMjk4O9P2M_vy4KDn7afLQkc_8yjjJ5Hk",
  authDomain: "bcs-web-data.firebaseapp.com",
  databaseURL: "https://bcs-web-data-default-rtdb.firebaseio.com",
  projectId: "bcs-web-data",
  storageBucket: "bcs-web-data.appspot.com",
  messagingSenderId: "142534024810",
  appId: "1:142534024810:web:56da47427c9f0612333413",
};

const db = firebase.initializeApp(firebaseConfig);

export default db;
