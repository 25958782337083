import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 50px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Adress = styled.div`
  color: black;
  background-color: #dcdcdc;
  width: 80%;
  margin-bottom: 25px;
  margin-top: 25px;
  border-radius: 15px;
  font-size: 14px;
  padding: 16px;
  margin-right: 55px;
  text-align: center;
  font-family: "Montserrat";
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: grey;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
  }
`;

export const Img = styled.img`
  height: 400px;
  width: 400px;
  padding: 15px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 300px;
    width: 300px;
  }
`;

export const AdressContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
