import React from "react";
import Fade from "react-reveal/Fade";
import { Container, Mail, Logo } from "./styles";
import logo from "../../assets/images/logo.png";

export const Footer = () => {
  return (
    <Fade bottom>
      <Container>
        <Fade left>
          <Logo src={logo} />
        </Fade>
        <Fade right>
          <a
            href="mailto:info@bcscompliance.com"
            style={{ textDecoration: "none" }}
          >
            <Mail>INFO@BCSCOMPLIANCE.COM</Mail>
          </a>
        </Fade>
      </Container>
    </Fade>
  );
};
