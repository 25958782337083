import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #151518;
  height: 75px;
  flex-direction: row;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const Logo = styled.img`
  margin-left: 40px;
  width: 170px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 100px;
  }
`;

export const Mail = styled.div`
  color: white;
  text-decoration: none;
  margin-right: 40px;
  font-size: 14px;
  font-family: "Montserrat";
  @media (max-width: 768px) {
    margin-right: 0px;
    font-size: 12px;
  }
`;
