import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;

export const CarouselContainer = styled.div`
  width: 95%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 5px;
  }
`;

export const Img = styled.img`
  width: 100px;
  @media (max-width: 768px) {
    width: 40px;
    padding: 5px;
  }
`;
