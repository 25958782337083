import styled from "styled-components";

export const Container = styled.div`
  background-color: #151518;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
`;

export const AppBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dcdcdc;
  flex-direction: row;
  height: 60px;
  width: 100%;
`;

export const Img = styled.img`
  width: 250px;
`;

export const MenuLabel = styled.text`
  color: #151518;
  font-size: 16px;
  padding: 80px;
  font-weight: 500;
  font-family: "Montserrat";
  text-transform: Uppercase;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
  }
`;
