import React, { useState } from "react";
import {
  Description,
  ServiceSection,
  IconContainer,
  Icon,
  ServiceTitle,
  DialogTitle,
  DialogContainer,
  DialogHeader,
  DialogRecord,
  DialogRecordText,
  Row,
  Marker,
} from "./styles";
import { services } from "../../const/services";
import { ScreenSection } from "../../components/ScreenSection";
import Dialog from "@material-ui/core/Dialog";
import { Fade, Zoom } from "react-reveal";

export const Services = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [dialogInfo, setDialogInfo] = useState({
    title: "",
  });

  const handleOpenDialog = (info) => {
    setDialogInfo({
      title: info.dialogTitle,
    });
    const records = info.description;
    setRecords(records);
    setIsOpen(true);
  };

  return (
    <ScreenSection title="Servicios">
      <Fade bottom>
        <Description>
          <b>Somos una consultora independiente</b>
          que ofrece servicios de Cumplimiento en Latinoamérica
          <br />
          desde nuestras oficinas en Buenos Aires y Montevideo.
        </Description>
        <ServiceSection>
          {services.map((item) => (
            <IconContainer onClick={() => handleOpenDialog(item)}>
              <Icon src={item.src} />
              <ServiceTitle>{item.title}</ServiceTitle>
            </IconContainer>
          ))}
        </ServiceSection>

        <Dialog
          onClose={() => setIsOpen(false)}
          aria-labelledby="ServicesDialog"
          open={isOpen}
        >
          <DialogHeader>
            <DialogTitle> {dialogInfo.title} </DialogTitle>
          </DialogHeader>
          <DialogContainer>
            <DialogRecord>
              {records.map((item) => (
                <Zoom duration={500}>
                  <Row>
                    <Marker>•</Marker>
                    <DialogRecordText>{item}</DialogRecordText>
                  </Row>
                </Zoom>
              ))}
            </DialogRecord>
          </DialogContainer>
        </Dialog>
      </Fade>
    </ScreenSection>
  );
};
