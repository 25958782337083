import CarlosBurundarena from "../assets/images/CarlosBurundarena.png";
import GustavoJuana from "../assets/images/GustavoJuana.png";
import DeliaFerreiraRubio from "../assets/images/DeliaFerreiraRubio.png";
import CarlosPini from "../assets/images/CarlosPini.png";
import EvaLamas from "../assets/images/EvaLamas.png";
import StephaniBarreto from "../assets/images/StephaniBarreto.png";
import FranciscoTorres from "../assets/images/FranciscoTorres.png";
import BelenErazo from "../assets/images/BelenErazo.png";
import FedericoSalas from "../assets/images/FedericoSalas.png";
import GuadalupeVarela from "../assets/images/GuadalupeVarela.png";

export const team = [
  {
    name: "CARLOS BURUNDARENA",
    rol: "MANAGING DIRECTOR",
    src: CarlosBurundarena,
    description:
      "Fundador de BCS, responsable de la gestión de la Compañía. Expositor frecuente en foros internacionales.",
    records: [
      "Profesor Universidad de Buenos Aires en posgrado “Banca Internacional y Prevención de Lavado de Dinero”",
      "Citibank, Banco de la Nación Argentina, Banco Santander RIO (Argentina) y Bansud (Argentina) en Europa, Estados Unidos y Latinoamérica , en posiciones gerenciales y de Directorio",
      "Asesor de Bancos Internacionales en Latinoamérica",
      "Licenciado en Economía, Universidad de Buenos Aires",
    ],
    mail: "cburundarena@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/carlos-burundarena-127b391/",
  },
  {
    name: "GUSTAVO JUANA",
    rol: "DIRECTOR",
    src: GustavoJuana,
    description:
      "CAMS. Dirige proyectos de consultoría e implementación de acuerdos de intercambio de información financiera internacional (FATCA / OCDE), de evaluación de riesgo coporativo LAFT  y de Programas de Prevención de Lavado de Activos y Financiamiento del Terrorismo.",
    records: [
      "Profesor Universidad de Buenos Aires en posgrado “Prevención de Lavado de Activos y Financiamiento del Terrorismo",
      "Auditor de Prevención de Lavado de Activos y Financiamiento del Terrorismo en los registros del Banco Central del Uruguay y la SEPRELAD (Paraguay)",
      "Oficial de Cumplimiento, Oficial Regulatorio, Oficial OFAC y Gerente de Operaciones, Administración y Sistemas, de Banco de la Nación Argentina Sucursal Nueva York",
      "Licenciado en Economía, Universidad Católica Argentina",
    ],
    mail: "gjuana@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/gustavo-juana-0906a828/",
  },
  {
    name: "DELIA ",
    lastName: "FERREIRA RUBIO",
    rol: "DIRECTORA",
    src: DeliaFerreiraRubio,
    description:
      "Presidenta de Transparency International. Consultora independiente en temas de lucha contra la corrupción y financiamiento de la política para varias organizaciones internacionales y ONG’s.",
    records: [
      "Presidenta de Poder Ciudadano (Argentina)",
      "Miembro del Directorio de Transparency International",
      "Autora de numerosas publicaciones sobre cultura y políticas de las instituciones democráticas, la política comparada y la ética pública y parlamentaria",
      "Abogada de la Facultad de Derecho de la Universidad de Córdoba (Argentina)",
      "Doctorada en la Universidad Complutense de Madrid",
    ],
    mail: "dferreira@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/delia-ferreira-rubio-94ba7210/",
  },
  {
    name: "GUADALUPE ",
    lastName: "DIAZ VARELA",
    rol: "CHIEF OPERATING OFFICER",
    src: GuadalupeVarela,
    description:
      "Supervisa el funcionamiento diario del área administrativa y operativa. Participa además en proyectos de consultoría y mantenimiento de Prevención de Lavado de Activos y Financiamiento del Terrorismo y es la responsable de la organización de eventos de BCS.",
    records: [""],
    mail: "gdiazvarela@bcscompliance.com",
  },
  {
    name: "STHEFANI BARRETO",
    rol: "DIRECTORA",
    src: StephaniBarreto,
    description:
      "Certificada ACAMS como Especialista en Antilavado de Dinero. Remediación de Carteras de clientes, Consultoría, Auditoría.",
    records: [
      "Operadora, Asistente de Cumplimiento, y luego Oficial de Cumplimiento en More Money Transfers y Giros More Uruguay S.A.",
      "Oficial de Cumplimiento en Raberil Trading S.A.",
      "Seminarios y conferencias de Prevención de Lavado de Activos y Financiamiento del Terrorismo",
    ],
    mail: "sbarreto@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/sthefani-barreto-cams-049a9249/",
  },
  {
    name: "CARLOS PINI",
    rol: "DIRECTOR",
    src: CarlosPini,
    description:
      "Auditor de Prevención de Lavado de Activos y Financiamiento del Terrorismo. Especialista en remediación de carteras de clientes de alto riesgo.",
    records: [
      "Consultor de Lavado de Activos y Financiamiento del Terrorismo en Argentina, Chile, Uruguay y Paraguay, realizando más de 100 auditorías en los últimos años para BCS",
      "Oficial de Cumplimiento  y Gerente de Cumplimiento Normativo de BBVA Uruguay",
      "Asesor Externo en cumplimiento normativo de BBVA Paraguay",
      "Gerente de Organización y Sistemas, y Gerente de Sucursal de Banco Francés (Argentina)",
      "Contador Público Nacional, Universidad de Buenos Aires",
    ],
    mail: "cpini@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/carlos-pini-1aa65437/",
  },
  {
    name: "FEDERICO ",
    lastName: "MORETON SALAS",
    rol: "SENIOR",
    src: FedericoSalas,
    description:
      "CAMS. Realiza evaluaciones de riesgo, participa en auditorías y servicios de asistencia en cumplimiento a clientes.",
    records: [
      "Gerente de Prevención de Lavado de Dinero en Banco Finansur",
      "Analista de Prevención de Lavado de Dinero en Banco Comafi",
      "Análisis de Riesgos en Banco Comafi",
    ],
    mail: "fmoretonsalas@bcscompliance.com",
    linkedin:
      "https://www.linkedin.com/in/federico-moreton-salas-1b44721a/?originalSubdomain=ar",
  },
  {
    name: "FRANCISCO TORRES",
    rol: "SENIOR",
    src: FranciscoTorres,
    description: "Remediación de Carteras de Clientes, Consultoría, Assurance.",
    records: [
      "Tareas gerenciales dentro y fuera de Uruguay, en Banca Privada, Comercial Mayorista y Minorista del Grupo BBVA",
      "Asesor financiero certificado por el IEF de Barcelona",
    ],
    mail: "ftorres@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/franciscotorresbaitx/",
  },
  {
    name: "BELÉN ERAZO",
    rol: "PARTNER",
    src: BelenErazo,
    description:
      "Implementación de Proyectos FATCA/OCDE, Asesora en prevención de lavado de activos y financiamiento del terrorismo.",
    records: [
      "Directora de Prevención de la Unidad de Análisis Financiero (UAF)",
      "Representante de Ecuador ante el GAFI.  Evaluadora certificada por GAFISUD",
      "Instructora de cursos autorizados por la Unidad de Análisis Financiero y Económico (UAFE)",
      "Ingeniera Comercial con mención en Finanzas de la Universidad Católica del Ecuador",
      "Magister en Auditoría y Finanzas de la Universidad Tecnológica Equinoccial (Ecuador)",
    ],
    mail: "berazo@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/belen-erazo-982aa260/",
  },
  {
    name: "EVA LAMAS",
    rol: "PARTNER",
    src: EvaLamas,
    description:
      "Consultoría en prevención de lavado de activos y financiamiento del terrorismo en Latinoamérica y el Caribe.",
    records: [
      "FIBA (Florida International Bankers Association): Diseño de un Programa de Comercio Exterior  y una Certificación  para Oficiales  con fuerte enfoque en Riesgos y Cumplimiento",
      "Directora de Citibank, responsable  del negocio de Comercio Internacional  en América Latina y el Caribe",
      "Directora Regional Ejecutiva del  First Caribbean Int. Bank (Grupo CIBC de Canadá) para su Negocio de Banca Transaccional y como Directora del Área de  Clientes  y Corresponsales para el Bermuda Commercial Bank con sede en Hamilton",
    ],
    mail: "elamaspartner@bcscompliance.com",
    linkedin: "https://www.linkedin.com/in/eva-lamas-295a561/",
  },
];
