import React from "react";
import { AppBar, MenuLabel } from "../styles";

export const NavBar = () => {
  return (
    <AppBar>
      <MenuLabel
        onClick={() => window.scrollTo({ top: 260, behavior: "smooth" })}
      >
        Servicios
      </MenuLabel>
      <MenuLabel
        onClick={() => window.scrollTo({ top: 780, behavior: "smooth" })}
      >
        Equipo
      </MenuLabel>
      <MenuLabel
        onClick={() => window.scrollTo({ top: 1700, behavior: "smooth" })}
      >
        Clientes
      </MenuLabel>
      <MenuLabel
        onClick={() => window.scrollTo({ top: 2300, behavior: "smooth" })}
      >
        Contacto
      </MenuLabel>
    </AppBar>
  );
};
