import styled from "styled-components";

export const Icon = styled.img`
  width: 120px;
  transition: 0.5s;
  &:hover {
    width: 140px;
    transition: 0.5s;
  }
`;

export const ProfilePicture = styled.img`
  width: 150px;
  border-radius: 100px;
  margin-bottom: 15px;
  transition: 0.5s;
  &:hover {
    width: 170px;
    transition: 0.5s;
  }
  @media (max-width: 768px) {
    width: 75px;
  }
  z-index: 10;
`;

export const TeamCard = styled.div`
  display: flex;
  margin: 35px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Name = styled.div`
  font-family: "Montserrat";
  font-size: 16px;
  width: 100%;
  margin-bottom: 5px;
  color: #151518;
  text-align: center;
`;

export const LastName = styled.div`
  font-family: "Montserrat";
  font-size: 16px;
  width: 100%;
  margin-bottom: 5px;
  color: #151518;
  text-align: center;
`;

export const Rol = styled.div`
  font-family: "Montserrat";
  font-size: 14px;
  color: grey;
  text-align: center;
`;

// DIALOG

export const DialogTitle = styled.div`
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  background-color: #dcdcdc;
`;

export const DialogTeamSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px;
  align-items: center;
  font-family: "Montserrat";
`;

export const DialogDescription = styled.div`
  justify-content: left;
  align-items: left;
  color: #151518;
  font-family: "Montserrat";
  font-size: 12px;
  margin-top: 15px;
`;

export const DialogHeader = styled.div`
  background-color: #f8f8f8;
  padding: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DialogName = styled.div`
  font-family: "Montserrat";
  font-size: 15px;
  color: #151518;
  font-weight: 500;
`;

export const DialogRol = styled.div`
  font-family: "Montserrat";
  font-size: 12px;
  color: grey;
  text-transform: uppercase;
`;

export const DialogPicture = styled.img`
  margin-right: 15px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
`;

export const DialogRecord = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const DialogRecordText = styled.div`
  font-family: "Montserrat";
  font-size: 12px;
  color: #151518;
  margin-bottom: 7px;
`;

export const RecordTitle = styled.div`
  font-family: "Montserrat";
  font-size: 14px;
  color: #151518;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 250px 250px 250px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-template-columns: auto;
  }
`;

export const ContactInfo = styled.div`
  font-family: "Montserrat";
  font-size: 13px;
  margin-bottom: 5px;
  color: #151518;
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-left: 25px;
  margin-bottom: 15px;
`;
