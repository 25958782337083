import React from "react";
import Fade from "react-reveal/Fade";
import { Container, Img } from "./styles";
import { NavBar } from "./components";

import logo from "../../assets/images/logo.png";

export const Header = () => {
  return (
    <Fade top>
      <Container className="navbar">
        <Img src={logo} alt="logo" />
      </Container>
      <NavBar />
    </Fade>
  );
};
