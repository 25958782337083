import React from "react";
import { Container, Title } from "./styles";
import Fade from "react-reveal/Fade";

export const ScreenSection = ({ title, children }) => {
  return (
    <Container>
      <Fade bottom>
        <Title>{title}</Title>
      </Fade>
      {children}
    </Container>
  );
};
