import React, { useState } from "react";
import { ScreenSection } from "../../components/ScreenSection";
import { Fade } from "react-reveal";
import urgMap from "../../assets/images/urg.jpg";
import argMap from "../../assets/images/arg.jpg";
import { Container, Column, Adress, Img } from "./styles";

export const Contact = () => {
  const [showArgentineMap, setShowArgentinaMap] = useState(true);

  return (
    <ScreenSection title="Contacto">
      <Container>
        <Fade bottom>
          <Column>
            <Adress onClick={() => setShowArgentinaMap(true)}>
              (54) 11 4313-1288
              <br /> San Martín 1143 - Piso 5 <br /> C1004AAW - Buenos Aires
            </Adress>
            <Adress onClick={() => setShowArgentinaMap(false)}>
              (598) 2 916-4479 <br /> Ituzaingó 1482 - Piso 3 Of. 301
              <br />
              CP 11000 - Montevideo
            </Adress>
          </Column>
          <a
            href={
              showArgentineMap
                ? "https://www.google.com.ar/maps/place/San+Mart%C3%ADn+1143,+C1004+CABA/@-34.5943496,-58.3764156,17z/data=!3m1!4b1!4m5!3m4!1s0x95bccab5740de465:0x4e7ebedb6b60df2d!8m2!3d-34.594354!4d-58.3742269"
                : "https://www.google.com.ar/maps/place/Ituzaing%C3%B3+1482,+11000+Montevideo,+Departamento+de+Montevideo,+Uruguay/@-34.9050684,-56.2070328,17z/data=!3m1!4b1!4m5!3m4!1s0x959f7f874baf470f:0x773bff7297d41e30!8m2!3d-34.9050728!4d-56.2048441"
            }
          >
            <Img src={showArgentineMap ? argMap : urgMap} />
          </a>
        </Fade>
      </Container>
    </ScreenSection>
  );
};
