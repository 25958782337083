import LAFT from "../assets/images/1b.png";
import AC from "../assets/images/2b.png";
import CR from "../assets/images/3b.png";
import FATCA from "../assets/images/4b.png";

export const services = [
  {
    title: "LA/FT",
    dialogTitle: "LAVADO DE ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO",
    src: LAFT,
    description: [
      "Diseñamos y asistimos a nuestros clientes en la implementación de programas de prevención, incluyendo evaluaciones de riesgo y manuales de políticas y procedimientos.",
      "Brindamos capacitación presencial a Directorios, Areas de Cumplimiento y Niveles Gerenciales, y soluciones de e-learning para el resto del personal. Estas últimas pueden incluir módulos diferenciales para las distintas áreas (ejemplo: Comercio Exterior, Administración de Patrimonios, Sucursales Comercial, Sucursales Operativa, entre otros).",
      "Remediamos carteras de clientes para que cumplan con los requisitos regulatorios y particulares de la entidad.",
      "Hacemos evaluaciones independientes de riesgo de LA/FT y cumplimiento normativo en varios países de latinoamérica. Estamos inscriptos en los registros de auditores de LA/FT de Argentina, Uruguay y Paraguay.",
      "Hacemos análisis forense y evaluaciones de documentación e información presentada para justificar el origen de fondos de operaciones propias de nuestros clientes, o de los clientes de nuestros clientes.",
      "Evaluamos y diseñamos  sistemas de monitoreo de transacciones.",
    ],
  },
  {
    title: "ANTICORRUPCION",
    dialogTitle: "ANTICORRUPCION",
    src: AC,
    description: [
      "Diseñamos y asistimos a nuestros clientes en la implementación de programas de integridad, incluyendo evaluaciones de riesgo, códigos de ética, manuales de políticas y procedimientos e implementación de canales de denuncias",
      "Brindamos Capacitación en forma presencial y a través de e-learning.",
      "Realizamos evaluaciones independientes acerca de la calidad y efectividad de Programas de Integridad.",
      "Ofrecemos el servicio de co-sourcing para el analisis de clientes / contrapartes / socios y para el monitoreo de la ejecución transparente de los contratos en operaciones con el Estado u organismos internacionales.",
    ],
  },
  {
    title: "CUMPLIMIENTO REGULATORIO",
    dialogTitle: "CUMPLIMIENTO REGULATORIO",
    src: CR,
    description: [
      "Cumplimiento regulatorio: Evaluamos la efectividad de sistemas de control interno y podemos además participar en la implementación de los mismos.",
      "Inscripción de Casas de Cambio en el FINCEN de los Estados Unidos y servicio de cumplimiento de normativa americana (BSA, Bank Secrecy Act).",
    ],
  },
  {
    title: "FATCA/OCDE",
    dialogTitle: "FATCA/OCDE",
    src: FATCA,
    description: [
      "Evaluamos aplicabilidad e impacto de estos acuerdos de intercambio de información.",
      "Remediamos bases de clientes.",
      "Damos servicios de 'mantenimiento', capacitaciones presenciales y por e-learning.",
      "Asesoramos en la preparación de los reportes regulatorios.",
    ],
  },
];
