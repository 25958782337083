import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import {
  DialogName,
  DialogContainer,
  DialogRecord,
  DialogRol,
  DialogRecordText,
  DialogHeader,
  DialogPicture,
  CardsContainer,
  RecordTitle,
  ProfilePicture,
  DialogTeamSection,
  TeamCard,
  Name,
  LastName,
  Rol,
  ContactSection,
  ContactInfo,
  DialogDescription,
} from "./styles";
import { team } from "../../const/team";
import { ScreenSection } from "../../components/ScreenSection";
import Dialog from "@material-ui/core/Dialog";

export const Team = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [dialogInfo, setDialogInfo] = useState({
    name: "",
    lastName: "",
    rol: "",
    src: "",
    description: "",
    mail: "",
    linkedin: "",
  });

  const handleOpenDialog = (info) => {
    setDialogInfo({
      name: info.name,
      lastName: info.lastName,
      rol: info.rol,
      src: info.src,
      description: info.description,
      mail: info.mail,
      linkedin: info.linkedin,
    });
    const records = info.records;
    setRecords(records);
    setIsOpen(true);
  };

  return (
    <ScreenSection title="EQUIPO">
      <CardsContainer>
        {team.map((item) => (
          <Fade bottom>
            <TeamCard>
              <ProfilePicture
                src={item.src}
                onClick={() => handleOpenDialog(item)}
              />
              <Name>{item.name}</Name>
              {item.lastName && <LastName> {item.lastName} </LastName>}
              <Rol>{item.rol}</Rol>
            </TeamCard>
          </Fade>
        ))}
      </CardsContainer>
      <Dialog
        onClose={() => setIsOpen(false)}
        aria-labelledby="ServicesDialog"
        open={isOpen}
      >
        <DialogHeader>
          <DialogPicture src={dialogInfo.src} />
          <DialogTeamSection>
            <DialogName>
              {dialogInfo.name}
              {dialogInfo.lastName}
            </DialogName>
            <DialogRol>{dialogInfo.rol}</DialogRol>
            <DialogDescription>{dialogInfo.description}</DialogDescription>
          </DialogTeamSection>
        </DialogHeader>
        <DialogContainer>
          {records.length > 1 && (
            <DialogRecord>
              <RecordTitle>Antecedentes:</RecordTitle>
              {records.map((item) => (
                <DialogRecordText>• {item}</DialogRecordText>
              ))}
            </DialogRecord>
          )}
        </DialogContainer>
        <ContactSection>
          {dialogInfo.linkedin && (
            <ContactInfo>
              <a href={dialogInfo.linkedin} style={{ textDecoration: "none" }}>
                Linkedin
              </a>
            </ContactInfo>
          )}
          <ContactInfo>{dialogInfo.mail}</ContactInfo>
        </ContactSection>
      </Dialog>
    </ScreenSection>
  );
};
