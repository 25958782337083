import styled from "styled-components";

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-size: 16px;
  width: 100%;
  text-align: center;
  line-height: 25px;
  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 15px;
  }
`;

export const ServiceSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 10%;
  flex-direction: column;
  padding: 55px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

export const ServiceTitle = styled.div`
  padding-top: 35px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-size: 15px;
  text-align: center;
  font-weight: 500;
`;

export const Icon = styled.img`
  width: 120px;
  transition: 0.5s;
  &:hover {
    width: 140px;
    transition: 0.5s;
  }
`;

export const DialogTitle = styled.div`
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  background-color: #dcdcdc;
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  align-items: center;
`;

export const DialogHeader = styled.div`
  background-color: #dcdcdc;
  padding: 25px;
  justify-content: center;
  align-items: center;
`;

export const DialogRecord = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
`;

export const DialogRecordText = styled.div`
  font-family: "Montserrat";
  font-size: 13px;
  color: #151518;
  margin-bottom: 15px;
`;

export const Marker = styled.div`
  font-weight: 600;
  margin-right: 15px;
`;

export const Row = styled.div`
  flew-direction: column;
  display: flex;
  justify-content: left;
  align-items: flex-start;
`;
