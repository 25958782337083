import React, { useState, useEffect, useCallback } from "react";
import db from "../../firebase.config";
import { Container, Img, ImgContainer, Row } from "./styles";
import { ScreenSection } from "../../components/ScreenSection";
import RightArrow from "../../assets/images/RightArrow.png";
import LeftArrow from "../../assets/images/LeftArrow.png";

export const Clients = () => {
  const [slides, setSlides] = useState([]);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const onRightClick = useCallback(() => {
    if (counter < slides.length - 1) {
      setCounter(counter + 1);
    }
  }, [counter, slides]);

  const onLeftClick = useCallback(() => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  }, [counter]);

  const fetchData = async () => {
    const clients = await db.database().ref();
    clients.on("value", (data) => {
      const response = data.val();
      let array = [];
      const newArray = [];
      console.log(Object.values(response).length);
      Object.values(response).forEach((value) => {
        if (array.length < 39) {
          array.push(value);
        } else if (array.length === 39) {
          array.push(value);
          newArray.push(array);
          array = [];
        }
      });
      setSlides(newArray);
    });
  };

  return (
    <ScreenSection title="Clientes">
      {!!slides.length && (
        <Container>
          <div onClick={onLeftClick}>
            <img
              style={{ width: 25, height: 25 }}
              alt="LeftArrow"
              src={LeftArrow}
            />
          </div>
          <Row>
            {slides[counter].map((img) => (
              <ImgContainer>
                <Img src={img} />
              </ImgContainer>
            ))}
          </Row>
          <div onClick={onRightClick}>
            <img
              style={{ width: 25, height: 25 }}
              alt="RightArrow"
              src={RightArrow}
            />
          </div>
        </Container>
      )}
    </ScreenSection>
  );
};
