import React from "react";
import { Header } from "../../components/Header";
import { Services } from "../Services";
import { Clients } from "../Clients";
import { Team } from "../Team";
import { Footer } from "../Footer";
import { Contact } from "../Contact";

export const Home = () => {
  return (
    <>
      <Header />
      <Services />
      <Team />
      <Clients />
      <Contact />
      <Footer />
    </>
  );
};
