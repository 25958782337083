import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 75px;
  position: relative;
  z-index: 50;
  width: 100%;
`;

export const Title = styled.div`
  font-family: "Montserrat";
  text-transform: Uppercase;
  font-size: 25px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
